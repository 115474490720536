import { Injectable } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class MessageTranslaterService {
  translations;
  constructor(private translate: TranslateService) {
    this.translate.get([
      'customTrans.ETACalculatedSuccessfully',
      'customTrans.ETAnotCalculated',
      'customTrans.problemInTour',
      'customTrans.givenOrderIDNotFound',
      'customTrans.authProblem',
    ]).subscribe(trans => {
      this.translations=trans;
    });
  }
  translateMessage(message:any){
    if(message=="ETA Calculated Successfully"){
      return this.translations['customTrans.ETACalculatedSuccessfully']
    }else if(message=="ETA not calculated"){
      return this.translations['customTrans.ETAnotCalculated']
    }else if(message=="problem in tour"){
      return this.translations['customTrans.problemInTour']
    }else if(message=="given Order ID not found"){
      return this.translations['customTrans.givenOrderIDNotFound']
    }else if(message=="auth problem"){
      return this.translations['customTrans.authProblem']
    }else{
      return message;
    }
  }
}
