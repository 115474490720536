import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  constructor(private http: HttpClient) {
  }

  testApi(): Observable<any> {
    let url=`${environment.apiUrl}`;
    return this.http.get(url);
  }
  trackOrder(orderID): Observable<any> {
    let url=`${environment.apiUrl}/calculateETA/${orderID}`;
    return this.http.get(url);
  }
}
